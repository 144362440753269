<template>
  <div class="UserDomainsParentView">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'UserDomainsParentView',
};
</script>
